
import React from 'react';
import Card from '@mui/material/Card';
import { Paper, Table, TableCell, TableHead, TableRow, CardHeader, Typography, TableContainer, TableBody, Stack, Link, useTheme, useMediaQuery, Box } from '@mui/material';
import { CREDITS, OPTIONS, STATUS } from 'credits';
import { LoopOutlined } from '@mui/icons-material';


export function Invitations({invitations, isLoading}) {

    const theme = useTheme()

    const isMobileResponsive = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Card variant="outlined">
            <CardHeader title="Mes invitations envoyées" />
            <TableContainer component={Paper}>
                <Box sx={{overflow: 'auto'}}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell>Offre choisie</TableCell>
                            <TableCell>Option</TableCell>
                            <TableCell>Statut</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <LoopOutlined />
                                        <Typography fontWeight={"bold"}>
                                            Chargement...
                                        </Typography>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {invitations.map((invitation) => (
                            
                                <TableRow key={invitation.id}>
                                    <TableCell>{invitation.photograph_email}</TableCell>
                                    <TableCell>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography fontWeight={"bold"}>
                                                {invitation.photograph_name} 
                                            </Typography>
                                            {!isMobileResponsive && (
                                                <Link
                                                    variant="caption"
                                                    component={"button"}
                                                    onClick={() => {
                                                        window.open(`https://app.sportpxl.com/photograph/${invitation.photograph_id}`, '_blank');
                                                    }}
                                                >
                                                    Voir le profil
                                                </Link>
                                            )}
                                        </Stack>
                                    </TableCell>
                                    <TableCell>{CREDITS[invitation.provisioned_credits].fullText}</TableCell>
                                    <TableCell>{OPTIONS[invitation.option].name}</TableCell>
                                    <TableCell>{STATUS[invitation.status].chip}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}

                </Table>
                </Box>
                </Box>
            </TableContainer>
        </Card>


    );
}

export default Invitations;